<template>
  <!--begin::Dashboard-->
  <div class="text-end mb-2">
    <!--begin::Menu-->
    <button
      type="button"
      class="btn"
      id="btn-new-occurence"
      data-kt-menu-trigger="hover"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="top-end"
      @click="incidencia"
    >
      + Nova Incidencia
    </button>
    <!-- <Dropdown1></Dropdown1> -->
    <!--end::Menu-->
  </div>
  <!--end::Toolbar-->

  <!--begin::TopCardsIndicators-->
  <div
    class="row g-5 gx-xxl-8"
    style="min-width: 102%; max-width: fit-content; width: auto;"
  >
    <div class="col-6 col-lg-3 col-xl-3 col-md-6">
      <TopCardNumbersInfo
        style="white-space: nowrap"
        :bgAndTextColor="bgAndTextColor2"
        :gradientStyle="gradientCard2"
        :tittle="tittleCard2"
        :tittleIcon="iconCard2"
        :info="card4Values"
        :value="processingOccurrences"
      ></TopCardNumbersInfo>
    </div>
    <div class="col-6 col-lg-3 col-xl-3 col-md-6">
      <TopCardNumbersInfo
        style="white-space: nowrap"
        :bgAndTextColor="bgAndTextColor1"
        :gradientStyle="gradientCard1"
        :tittle="tittleCard1"
        :tittleIcon="iconCard1"
        :info="card3Values"
        :value="thisMonthOccurences"
      ></TopCardNumbersInfo>
    </div>
    <div class="col-6 col-lg-3 col-xl-3 col-md-6">
      <TopCardNumbersInfo
        style="white-space: nowrap"
        :bgAndTextColor="bgAndTextColor3"
        :gradientStyle="gradientCard3"
        :tittle="tittleCard3"
        :tittleIcon="iconCard3"
        :info="card5Values"
        :value="closedOccurences"
      ></TopCardNumbersInfo>
    </div>
    <div class="col-6 col-lg-3 col-xl-3 col-md-6">
      <TopCardNumbersInfoPercentage
        style="white-space: nowrap"
        :gradientStyle="gradientCard4"
        :tittle="tittleCard4"
        :tittleIcon="iconCard4"
        :info="card6Values"
        :chartHeight="'200'"
        :btnColor:="'primary'"
        :value="occurencesWithResponsePercent"
      ></TopCardNumbersInfoPercentage>
    </div>
  </div>
  <div style="min-width: 100%; max-width: 100px">
    <OccurenceTable
      :tableName="tableName"
      :occurences="list"
      :pageProp="page"
      :pages="pages"
      :filterStatus="filterStatus"
      :filterStatusObject="filterStatusList"
      @paginatorHandler="paginatorHandler"
      @updateStatus="updateStatus"
      @queryForOccurences="queryForOccurences"
    ></OccurenceTable>
  </div>
  <!--end::TopCardsIndicators-->

  <!--end::Dashboard-->
</template>

<script>
import { defineComponent } from "vue";

import TopCardNumbersInfo from "@/components/InfoCards/TopCardOccurencesInfo.vue";
import TopCardNumbersInfoPercentage from "@/components/InfoCards/TopCardOccurencesPercentage.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

import store from "@/store";

import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { array } from "yup/lib/locale";

//import Dropdown1 from "@/components/dropdown/Dropdown2.vue";
import OccurenceTable from "@/components/customTables/OccurencesTable.vue";
import ApiService from "@/core/services/ApiService";
import router from "@/router/clean";

export default defineComponent({
  name: "Occurrences",
  components: {
    TopCardNumbersInfo,
    //Dropdown1,
    OccurenceTable,
    TopCardNumbersInfoPercentage,
  },
  data() {
    return {
      tittleCard1: "incidências este mês",
      tittleCard2: "Incidências em Análise",
      tittleCard3: "Incidências fechadas",
      tittleCard4: "Taxa de resposta",
      iconCard1: "icons/duotune/general/gen005.svg",
      iconCard2: "icons/duotune/general/gen012.svg",
      iconCard3: "icons/duotune/files/fil007.svg",
      iconCard4: "icons/duotune/communication/com010.svg",

      card3Values: {
        background: "icons/duotune/finance/fin009.svg",
        backgroundPositionX: "150%  ",
        backgroundPositionY: "30px",
        height: "110%",
      },
      card4Values: {
        background: "icons/duotune/ecommerce/ecm002.svg",
        backgroundPositionX: "center",
        backgroundPositionY: "bottom",
        height: "100%",
      },
      card5Values: {
        background: "icons/duotune/ecommerce/ecm003.svg",
        backgroundPositionX: "right",
        backgroundPositionY: "30px",
        height: "100%",
      },
      card6Values: {
        background: "icons/duotune/ecommerce/ecm002.svg",
        backgroundPositionX: "center",
        backgroundPositionY: "-20px",
        height: "60%",
      },

      gradientCard2:
          "background: linear-gradient(90deg, #FFF200 50%, #FFF200 89.83%);",
      gradientCard1:
          "background: linear-gradient(90deg, #FFF200 50%, #FFF200 89.83%);",
      gradientCard3:
          "background: linear-gradient(90deg, #FFF200 50%, #FFF200 89.83%);",
      gradientCard4:
          "background: linear-gradient(90deg, #FFF200 50%, #FFF200 89.83%);",

     
      bgAndTextColor3: "background-color: #f3f2f1; color: black;",
      bgAndTextColor2: "background-color: #f3f2f1; color: black;",
      bgAndTextColor1: "background-color: #f3f2f1; color: black;",


      tableName: "Histórico de Incidências",
      occurencesList: Array,
      occurencesIndicators: Object,
      page: 1,
      pages: 0,
      list: [],
      itemsPerPage: 5,
      filterStatus: ["Ativo", "Suspenso", "Concluído"],
      filterStatusList: [],
      searchInput: "",
      status: "",
      startDate: "",
      endDate: "",
    };
  },

  computed: {
    totalOccurences() {
      return this.occurencesIndicators["totalOccurences"] == undefined
          ? 0 + ""
          : this.occurencesIndicators["totalOccurences"];
    },
    processingOccurrences() {
      if(store.getters.getDashboardInvoices !== undefined && store.getters.getDashboardInvoices.incAbertas != undefined)
        return store.getters.getDashboardInvoices.incAbertas.toLocaleString('pt-pt');
      return 0  + "";
    },
    closedOccurences() {
      if(store.getters.getDashboardInvoices !== undefined && store.getters.getDashboardInvoices.incFechadas != undefined)
        return store.getters.getDashboardInvoices.incFechadas.toLocaleString('pt-pt');

      return 0   + "";
    },
    resolvingOccurences() {
      return this.occurencesIndicators["resolvingOccurences"] == undefined
          ? 0 + ""
          : this.occurencesIndicators["resolvingOccurences"];
    },
    occurencesWithResponsePercent() {
      if(store.getters.getDashboardInvoices !== undefined && store.getters.getDashboardInvoices.taxaResposta != undefined)
        return store.getters.getDashboardInvoices.taxaResposta

      return 0
    },
    thisMonthOccurences() {
      if (store.getters.getDashboardInvoices !== undefined && store.getters.getDashboardInvoices.incMonth != undefined)
        return store.getters.getDashboardInvoices.incMonth.toLocaleString('pt-pt');
      return 0
    },
  },


  watch: {
    //when new occurence is created we load all
    /*
    storeOccurencesQtt() {
      this.setAllOccurences();
      this.paginatorHandler(1);
    },
    */
  },
  methods: {
    incidencia() {
      router.push('/occurence/inc')
    },

    /*
    * ----------------DATA MANIPULATION----------------
    */

    setAllOccurences() {
      this.occurencesIndicators = store.getters.getOccurencesIndicators;
      this.occurencesList = store.getters.getOccurencesList;
    },
    paginatorHandler(page) {
      this.page = page;
      this.loadOccurences();
    },
    //filters by status, update pages, and list to show
    updateStatus(status) {
      if(status == "") {
        this.status = "";
        return;
      }
      this.status = this.filterStatusList.find((statusObj) => {
        return statusObj.strDescricao == status;
      }).strCodigo;
      this.paginatorHandler(1);
    },

    /*
    * ------------------API FETCHING------------------
    */

    loadOccurences() {
      let queryString = this.getQueryString(this.page);
      ApiService.get('incidencias', queryString).then((response) => {
        this.pages = response.data.TotalPages,
            this.list = response.data.Data
      });
    },
    loadOccurencesStatus() {
      ApiService.get('Utils/estadosDocsOccurences').then((response) => {
        this.filterStatusList = response.data.Data;
        this.filterStatus = response.data.Data.map((statusObj) => {
          return statusObj.strDescricao
        });
      });
    },


    /*
    * -------------------UTILS--------------------
    */

    getQueryString(page) {
      let queryString = `?pageNumber=${page}&pageSize=${this.itemsPerPage}&IntCodEntidade=${store.getters.currentUser.numcl}`;

      if(this.startDate) {
        queryString += `&DtmInicial=${this.startDate}`
      }
      if(this.endDate) {
        queryString += `&DtmFinal=${this.endDate}`
      }
      if(this.status) {
        queryString += `&intCodEstado=${this.status}`
      }
      if(this.searchInput) {
        queryString += `&numDoc=${this.searchInput}`
      }

      return queryString;

    },

    queryForOccurences(searchInput) {
      this.searchInput = searchInput.searchInput;
      this.startDate = searchInput.startDate;
      this.endDate = searchInput.endDate;
      this.paginatorHandler(1);
    },

    /*
    * -------------------STYLES-------------------
    */


  },
  mounted() {
    //instatiate store
    setCurrentPageTitle("Incidências");
    //only load last year at first
    let startDate = new Date();
    startDate.setFullYear(startDate.getFullYear() - 1);
    this.startDate = startDate.toISOString().substring(0,10);    
    this.loadOccurences();
    this.loadOccurencesStatus();
    store.dispatch(Actions.LOAD_DASH_INVOICES, store.getters.currentUser.numcl);
    //this.setAllOccurences();
    //this.paginatorHandler(1);
  },


});
</script>

<style scoped>
#btn-new-occurence:hover  {
  background-color: #DDF3FF;
  color: #0095E8;
}


#btn-new-occurence {
  background-color: #0095E8;
  color: white;
  min-width: 12%;
  font-size: 14px;
  border-radius: 11px;
}

.row-flex {
  display: flex;
  flex-wrap: wrap;
}

.text-end {
  text-align: end;
}
</style>
